import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import AppDataContext from 'app/app-data-context';
import commonStyles from 'styles/common.module.scss';
import Container from 'react-bootstrap/Container';
import styles from './index.module.scss';
import { titleCase } from 'title-case';
import { useDocumentTitle } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';
const knownSites = ['redmond', 'issaquah'];
export default function Page() {
    useDocumentTitle('Home - Hera Nails & Spa Intranet');
    const appCtx = useContext(AppDataContext);
    const location = useLocation();
    if (!appCtx.user?.username) {
        return (React.createElement(Container, { as: "main", fluid: "lg" },
            React.createElement("h1", { className: commonStyles.title }, "Welcome to Hera Nails & Spa Intranet"),
            React.createElement(Alert, { className: "bg-warning-subtle text-warning-emphasis" },
                React.createElement(Alert.Heading, null, "You are not signed in"),
                React.createElement("p", null,
                    "Only Hera Nails's employees can access this site.",
                    React.createElement("br", null),
                    "You must authenticate by",
                    ' ',
                    React.createElement(Alert.Link, { className: "text-warning-emphasis", href: `/login?${new URLSearchParams({
                            redirect_uri: `${location.pathname}${location.search}${location.hash}`,
                        })}` }, "signing in"),
                    ' ',
                    "first."))));
    }
    let groups = new Set(appCtx.user?.groups ?? []);
    return (React.createElement(Container, { as: "main", fluid: "lg" },
        React.createElement("h1", { className: commonStyles.title }, "Welcome to Hera Nails & Spa Intranet"),
        React.createElement("ul", { className: styles.menu }, knownSites.map((site) => (React.createElement("li", { key: site },
            React.createElement("span", { className: "display-6" }, titleCase(site)),
            React.createElement("ul", null,
                React.createElement("li", null, groups.has(`${site}_appointment_ro`) || groups.has(`${site}_appointment_fa`) ? (React.createElement("a", { href: `/${site}/appointments` }, "Appointments")) : (React.createElement("span", { className: "text-muted" }, "Appointments"))),
                React.createElement("li", null, groups.has(`${site}_payment_ro`) || groups.has(`${site}_payment_fa`) ? (React.createElement("a", { href: `/${site}/payments` }, "Payments")) : (React.createElement("span", { className: "text-muted" }, "Payments"))))))))));
}
