import 'util/nightjs';
import './layout.scss';
import { createRoutesFromElements, Route, Routes } from 'react-router-dom';
import fetchJSON, { FetchError } from '@nguyengg/lambdajs/fetch-json';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, Suspense, useState } from 'react';
import AppDataContext from './app-data-context';
import commonStyles from 'styles/common.module.scss';
import Container from 'react-bootstrap/Container';
import CookieNotice from 'components/cookie-notice';
import { ErrorBoundary } from 'react-error-boundary';
import Homepage from './homepage';
import Navbar from 'components/navbar';
import Spinner from 'react-bootstrap/Spinner';
import styles from './layout.module.scss';
const Appointments = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "appointments" */
'./appointments'));
const GiftCards = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "gift-cards" */
'./gift-cards'));
const Payments = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "payments" */
'./payments'));
export default function Layout({ context }) {
    const [queryCache] = useState(() => new QueryCache({
        onError(error) {
            console.trace(error);
            if (error instanceof FetchError) {
                if (error.status === 401 &&
                    document.cookie
                        .split(';')
                        .find((v) => v.trim().startsWith('__Host-csrf=') || v.trim().startsWith('sid='))) {
                    location.reload();
                    return;
                }
            }
        },
    }));
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: { queries: { queryFn: ({ queryKey }) => fetchJSON(queryKey[0]) } },
        queryCache,
    }));
    return (React.createElement(AppDataContext.Provider, { value: context },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Navbar, null),
            React.createElement("div", { className: styles.content },
                React.createElement(ErrorBoundary, { fallbackRender: ErrorFallback },
                    React.createElement(Routes, null, routes))),
            React.createElement(CookieNotice, null),
            React.createElement(Container, { as: "footer", className: "mt-4 mb-1", fluid: true },
                React.createElement("div", { className: "border-top border-bottom py-4 text-center" },
                    React.createElement("ul", { className: styles['footer-list'] },
                        React.createElement("li", null, "\u00A9 Hera Nails"),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/privacy.html" }, "Privacy")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/cookies.html" }, "Cookies")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/terms.html" }, "Terms"))))))));
}
const routes = [
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Homepage, null) }),
    React.createElement(Route, { key: "redmondAppointments", caseSensitive: true, path: "/redmond/appointments", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Appointment Books - Hera Nails in Redmond" }) },
            React.createElement(Appointments, { site: "redmond" })) }),
    React.createElement(Route, { key: "redmondGiftCards", caseSensitive: true, path: "/redmond/gift-cards", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Gift Card Inventory" }) },
            React.createElement(GiftCards, null)) }),
    React.createElement(Route, { key: "redmondPayments", caseSensitive: true, path: "/redmond/payments", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Payment Inventory - Hera Nails in Redmond" }) },
            React.createElement(Payments, { site: "redmond" })) }),
    React.createElement(Route, { key: "issaquahAppointments", caseSensitive: true, path: "/issaquah/appointments", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Appointment Books - Glitz & Glam" }) },
            React.createElement(Appointments, { site: "issaquah" })) }),
    React.createElement(Route, { key: "issaquahGiftCards", caseSensitive: true, path: "/issaquah/gift-cards", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Gift Card Inventory" }) },
            React.createElement(GiftCards, null)) }),
    React.createElement(Route, { key: "issaquahPayments", caseSensitive: true, path: "/issaquah/payments", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, { title: "Payment Inventory - Glitz & Glam" }) },
            React.createElement(Payments, { site: "issaquah" })) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
// used by server to check early if a route exists or not to return an early 404.
export const appRoutes = routes.map((r) => createRoutesFromElements(r)).reduce((a, b) => a.concat(b));
function NotFound() {
    return (React.createElement(Container, { as: "main", className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "404"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Page Not Found")),
        React.createElement("p", null,
            "Do you know where you're going to?",
            React.createElement("br", null),
            "Do you like the things that life is showing you?",
            React.createElement("br", null),
            "Where are you going to?",
            React.createElement("br", null),
            "Do you know",
            React.createElement("a", { className: "text-decoration-none", href: "https://www.youtube.com/watch?v=gsA-Xc6gWDE", rel: "external nofollow noopener" }, "?")),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
function ErrorFallback() {
    return (React.createElement(Container, { as: "main", className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "500"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Internal Server Error")),
        React.createElement("p", null,
            "Server has encountered an unknown error.",
            React.createElement("br", null),
            "Please reach out to the site administrator for assistance."),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
function Fallback({ title }) {
    return (React.createElement(Container, { as: "main", fluid: "lg" },
        React.createElement("h1", { className: commonStyles.title }, title),
        React.createElement(Spinner, { animation: "border" })));
}
