import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
export const defaultZone = 'America/Los_Angeles';
export const dayFormat = 'YYYY-MM-DD';
export function today(zone = defaultZone) {
    return dayjs().tz(zone).startOf('day');
}
dayjs.tz.setDefault(defaultZone);
function range(start, end, stepValue = 1, stepUnit, inclusiveEnd, format) {
    const cond = inclusiveEnd ? () => !start.isAfter(end) : () => start.isBefore(end);
    if (format) {
        const values = [];
        while (cond()) {
            values.push(start.format(format));
            start = start.add(stepValue, stepUnit);
        }
        return values;
    }
    const values = [];
    while (cond()) {
        values.push(start);
        start = start.add(stepValue, stepUnit);
    }
    return values;
}
export { dayjs, Dayjs, range };
