import { matchPath, NavLink, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import AppDataContext from 'app/app-data-context';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Icon from '@nguyengg/lambdajs/components/icon';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Set } from 'immutable';
import styles from './index.module.scss';
const navLinks = [
    { href: '/appointments', label: 'Appointments' },
    { href: '/gift-cards', label: 'Gift Cards' },
    { href: '/payments', label: 'Payments' },
];
const policyLinks = [
    { href: '/policies/privacy.html', label: 'Privacy Policy' },
    { href: '/policies/cookies.html', label: 'Cookies Policy' },
    { href: '/policies/terms.html', label: 'Terms and Conditions' },
];
export default function AppNavbar() {
    const { internetHost, user } = useContext(AppDataContext);
    const location = useLocation();
    const match = matchPath({
        path: '/:site/:page',
        caseSensitive: true,
        end: true,
    }, location.pathname);
    const { site = 'redmond' } = match?.params ?? {};
    const loginHref = `/login?${new URLSearchParams({
        redirect_uri: `${location.pathname}${location.search}${location.hash}`,
    })}`;
    const [shownCanvas, setShownCanvas] = useState(false);
    return (React.createElement(Navbar, { id: "navbar", className: styles.navbar, collapseOnSelect: true, expand: false, fixed: "top", variant: "dark", onSelect: () => setShownCanvas(false) },
        React.createElement(Container, { className: "d-none d-md-flex justify-content-center", fluid: true },
            React.createElement(Navbar.Brand, { className: styles.brand, href: "/" }, "Hera Nails Intranet")),
        React.createElement(Container, { className: "justify-content-between justify-content-md-center" },
            React.createElement(Nav, { className: "d-none d-md-flex flex-row" }, navLinks.map(({ href, label }, i) => (React.createElement(SmartNavLink, { key: i, className: "px-3", href: site + href }, label)))),
            React.createElement(Navbar.Brand, { className: classNames('d-flex d-md-none', styles['brand']), href: "/" }, "Hera Nails Intranet"),
            React.createElement(Button, { "aria-controls": "navigation_sidebar", className: styles['toggler'], variant: "light", onClick: () => setShownCanvas(true) },
                React.createElement(Icon, { name: "list" }))),
        React.createElement(Navbar.Offcanvas, { id: "navigation_sidebar", className: styles['nav-sidebar'], placement: "end", restoreFocus: true, show: shownCanvas, onHide: () => setShownCanvas(false) },
            React.createElement(Offcanvas.Header, { className: "justify-content-end" },
                React.createElement(Button, { className: classNames('d-flex d-md-none', styles['toggler']), variant: "light", onClick: () => setShownCanvas(false) },
                    React.createElement(Icon, { name: "x-lg" }))),
            React.createElement(Offcanvas.Body, { className: "text-center" },
                React.createElement(Nav, null,
                    React.createElement("span", { className: "heading pb-2" }, "Account"),
                    user ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "py-2" },
                            React.createElement("div", null,
                                React.createElement(Navbar.Text, null, user.name || '-'),
                                " ",
                                React.createElement(AccountIcon, { user: user })),
                            user.email && React.createElement(Navbar.Text, null, user.email)),
                        React.createElement(Nav.Link, { href: "/sign-out" }, "Sign out"))) : (React.createElement(Nav.Link, { href: loginHref }, "Sign in")),
                    React.createElement("hr", null),
                    React.createElement("span", { className: "heading pb-2 pt-1" }, "Pages"),
                    navLinks.map(({ href, label }, i) => (React.createElement(SmartNavLink, { key: i, href: site + href }, label))),
                    internetHost && React.createElement(Nav.Link, { href: `//${internetHost}` }, "Hera Nails & Spa Homepage"),
                    React.createElement("hr", null),
                    React.createElement("span", { className: "heading pb-2 pt-1" }, "Legal"),
                    policyLinks.map(({ href, label }, i) => (React.createElement(Nav.Link, { key: i, href: href }, label))))))));
}
/**
 * A Nav.Link that renders as react-router's NavLink if it's not active.
 *
 * The idea is that if the link is not active (i.e. if active is not explicitly specified, the link is treated as active
 * if the current location's pathname is the same as href) then react-router's NavLink is used to allow client-side
 * navigation similar to a single-page application (SPA). If the link is active, clicking on the link will reload the
 * page.
 */
function SmartNavLink({ active, children, href, ...props }) {
    const { pathname } = useLocation();
    return (React.createElement(Nav.Link, { active: active ?? pathname === href, as: pathname === href ? undefined : NavLink, href: href, to: href, ...props }, children));
}
function AccountIcon({ user }) {
    const groups = Set(user.groups);
    if (user.username.startsWith('signinwithapple_') && groups.has('SignInWithApple')) {
        return React.createElement(Icon, { name: "apple", className: "mb-1" });
    }
    if (user.username.startsWith('loginwithamazon_') && groups.has('LoginWithAmazon')) {
        return React.createElement(Icon, { name: "alexa", className: "mb-1", fill: "#00caff" });
    }
    if (user.username.startsWith('facebook_') && groups.has('Facebook')) {
        return React.createElement(Icon, { name: "facebook", className: "mb-1", fill: "#4267b2" });
    }
    if (user.username.startsWith('google_') && groups.has('Google')) {
        return React.createElement(Icon, { name: "google", className: "mb-1", fill: "#4285f4" });
    }
    return React.createElement(Icon, { name: "person", className: "mb-1", fill: "#495867" });
}
